<template>
  <BCard
    no-body
    class="facility-card shadow-card"
    @click="$emit('clicked', item)"
  >
    <div class="d-flex gap-2 justify-content-between align-items-center">
      <div class="d-flex flex-column gap-2">
        <div class="facility-card__header">
          <b-avatar
            variant="light-primary"
            :src="item.avatar"
            :text="avatarText(`${item.name}`)"
            size="32px"
          />
          <div>
            <BLink
              class="font-weight-bold text-dark"
              :to="`/facility/${item.id}`"
              :target="target"
            >
              {{ item.name }}
            </BLink>
            <div>
              <template v-if="item.address">
                {{ item.address }}
              </template>
              <template v-else>
                <span class="text-muted">Без адреса</span>
              </template>
            </div>
          </div>
        </div>
        <div class="facility-card__bottom">
          <div class="d-flex align-items-center gap-1">
            <FeatherIcon icon="UserIcon" />
            <template v-if="item && item.manager && item.manager.firstname">
              <BLink
                class="facility-card__bottom-manager"
                style="text-decoration: underline;"
                :to="`/employee/${item.manager.id}`"
              >
                {{ item.manager.firstname }} {{ item.manager.surname }}
              </BLink>
            </template>
            <template v-else>
              {{ t('facilities.manager') }}
            </template>
          </div>
          <div v-if="selectable">
            <BButton
              v-if="!selected"
              variant="primary"
              @click.stop="$emit('selected', item)"
            >
              Выбрать
            </BButton>
            <BButton
              v-else
              variant="secondary"
              disabled
            >
              Выбрано
            </BButton>
          </div>
          <!-- <div>сегодня</div> -->
        </div>
      </div>
    </div>
  </BCard>
</template>

<script>
import {
  BCard, BAvatar, BLink, BButton,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { useI18n } from '@/hooks/useI18n';

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BButton,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    selectable: Boolean,
    selected: Boolean,
    target: {
      type: String,
      default: '_self',
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
      avatarText,
    };
  },
};
</script>

<style lang="scss">
.facility-card {
  margin: 0;
  padding: 13px;
  gap: 17px;
  display: flex;

  & > div {
    flex: 1;
    & > div {
      flex: 1;
    }
  }

  &__header {
    display: flex;
    gap: 17px;
  }

  &__bottom {
    display: flex;
    gap: 17px;
    justify-content: space-between;

    &-manager {
      color: inherit !important;
      text-decoration: underline;
    }
  }
}
@import '~@/assets/scss/utils';
@include dark-theme {
  .facility-card {
    color: #d0d2d6;
    background-color: $theme-dark-body-bg;
  }
}
</style>
