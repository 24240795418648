<template>
  <div
v-if="endDate !== null"
  :class="['font-weight-normal', `text-${statuses[status].color}`]"
>
    {{ t(`facilities.contract.tillStatus.${statuses[status].name}`, { date: formattedEndDate }) }}
  </div>
</template>

<script>
import {
  ref, toRefs, watch,
} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';

export default {
  props: {
    endDate: {
      type: String,
      default: null,
    },
    // Разница в миллисекундах
    // для засчитывания статуса ending
    endingOffset: {
      type: Number,
      // 604800000 - неделя
      default: 604800000,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { endDate, endingOffset } = toRefs(props);
    const statuses = [
      {
        name: 'expired',
        color: 'danger',
      },
      {
        name: 'ending',
        color: 'warning ',
      },
      {
        name: 'active',
        color: 'success',
      },
    ];

    const date = ref(null);
    const formattedEndDate = ref(null);
    const status = ref(null);
    watch(endDate, () => {
      date.value = new Date(endDate.value);
      formattedEndDate.value = date.value.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });

      const curDateTime = Date.now();
      const endDateTime = date.value.getTime();
      const dateDifference = curDateTime - endDateTime;

      if (endDateTime < curDateTime) status.value = 0;
      else if (Math.abs(dateDifference) < endingOffset.value) status.value = 1;
      else status.value = 2;
    }, { immediate: true });

    return {
      t,
      statuses,
      formattedEndDate,
      status,
    };
  },
};
</script>
