<template>
  <MapView
    :items="facilities"
    :markers="markers"
    :with-servicemans="withServicemans"
    @mapChange="$emit('mapChange', $event)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:default="scope">
      <div class="facility-cards">
        <FacilityCard
          v-for="facility in facilities"
          :key="facility.id"
          :item="facility"
          :selectable="selectable"
          :target="cardTarget"
          :selected="facility.id === selected"
          @selected="$emit('selected', $event)"
          @clicked="scope.openPopup(facility)"
        />
      </div>
    </template>
  </MapView>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api';
import MapView from '@/components/mapView/MapView.vue';
import FacilityCard from "@/components/facilities/FacilityCard.vue";
import FacilityMapBallonCard from "@/components/facilities/FacilityMapBallonCard.vue";

export default {
  components: {FacilityCard, MapView },
  props: {
    facilities: {
      type: Array,
      default: () => [],
    },
    withServicemans: {
      type: Boolean,
      default: false
    },
    selectable: Boolean,
    selected: Number,
    cardTarget: {
      type: String,
      default: '_self',
    },
  },
  setup(props) {
    const { facilities, selectable } = toRefs(props);
    const markers = ref([]);

    watch(
      facilities,
      (array) => {
        const tMarkers = [];
        for (let i = 0; i < array.length; i += 1) {
          const facility = array[i];
          const marker = {};

          marker.id = facility.id;
          marker.latLng = [facility.lat, facility.lon];
          marker.data = facility;
          marker.props = {
            selectable: selectable.value,
          };
          marker.component = FacilityMapBallonCard;

          if (facility.lat && facility.lon) tMarkers.push(marker);
        }

        markers.value = tMarkers;
      },
      { immediate: true },
    );

    return {
      markers,
    };
  },
};
</script>

<style lang="sass">
.facility-cards
  display: flex
  flex-direction: column
  gap: 7px
  & .facility-card
    cursor: pointer
</style>
