import { inject, ref } from '@vue/composition-api';

export const useRouter = () => {
  const router = inject('router');
  const route = ref(router.currentRoute);
  const redirect = async (to) => router.push(to);

  return {
    router,
    route,
    redirect,
  };
};
