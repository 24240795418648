<template>
  <b-card
    no-body
    class="map overflow-hidden"
  >
      <template v-if="!coordsIsLoaded">
          <div id="loading-bg">
              <div class="loading-logo">
                  <Logo />
              </div>
              <div class="loading">
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
              </div>
          </div>
      </template>
      <yandexMap
          v-else
              :coords="center"
              :zoom="zoom"
              :settings="settings"
              :controls="[]"
              :cluster-options="clusterOptions"
              @map-was-initialized="initMap"
              @boundschange="getBounds"
              @sizechange="getBounds"
              @click="newMarker"
      >
          <map-marker
                  v-for="marker in markers"
                  :key="'marker' + marker.id"
                  :marker="marker"
                  :popup-to-open.sync="popupToOpen"
                  @selected="$emit('selected', $event)"
          />
          <template
                  v-if="withServicemans"
          >
              <request-marker
                      v-for="serviceman in servicemans"
                      :key="'serviceman' + serviceman.id"
                      :request="serviceman"
                      :type="'employee'"
                      @markerReady="onMarkerReady"
              >
                  <employee-map-balloon-card
                          :employee="serviceman"
                          map
                  />
              </request-marker>
          </template>
          <template v-if="userCoords">
              <ymapMarker
                  :coords="userCoords"
                  :marker-id="'userCoords' + Date.now()"
                  :icon="employeeIcon"
              />
          </template>
      </yandexMap>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import 'leaflet/dist/leaflet.css';
import {ref, toRefs, watch} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import RequestMarker from '@/components/requestsMap/components/RequestMarker.vue';
import {yandexMap, ymapMarker} from 'vue-yandex-maps';
import EmployeeMapBalloonCard from "@/components/employeeMapCard/employeeMapBalloonCard.vue";
import Logo from "@core/layouts/components/Logo.vue";
import MapMarker from './MapMarker.vue';

export default {
  components: {
      Logo,
      ymapMarker,
    BCard,
    MapMarker,
      EmployeeMapBalloonCard,
    RequestMarker,
      yandexMap,
  },
  props: {
      editMode: {
          type: Boolean,
          default: false
      },
    markers: {
      type: Array,
      default: () => ([]),
    },
    recenterTo: {
      type: Array,
      default: null,
    },
    popupToOpen: {
      type: Object,
      default: null,
    },
    zoom: {
      type: Number,
      default: 10,
    },
    withServicemans: {
      type: Boolean,
      default: false
    },
      centerByUserLocation: {
          type: Boolean,
          default: false
      },
  },
    setup(props, {emit}) {
        const {recenterTo, editMode, withServicemans, centerByUserLocation} = toRefs(props);
      const attribution = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
      const store = useStore();
      const initialCoordinates = ref(store.state.app.initialMapCoordinates);
      const center = ref(initialCoordinates);
      const servicemans = ref([]);
      const requestToOpen = ref(null);
      const markerToOpen = ref(null);

      const mapRef = ref(null);
      const map = ref(null);
      const userCoords = ref(null);
      const coordsIsLoaded = ref(false);

        // eslint-disable-next-line no-return-await
      const currentCenter = async () => {
          const userGeoCoords = await store.dispatch('app/getCoords');
          if (JSON.stringify(userGeoCoords) !== JSON.stringify(center.value)) {
              userCoords.value = userGeoCoords;
              center.value = userGeoCoords;
          }
          coordsIsLoaded.value = true;
      };
      if (centerByUserLocation.value) {
          currentCenter();
      } else {
          coordsIsLoaded.value = true;
      }

      watch(recenterTo, (value) => {
          if (value?.filter((i) => i).length) {
              center.value = value;
              emit('update:recenterTo', null);
          }
      }, {immediate: true});

      const getServiceMans = async () => {
          servicemans.value = (await store.dispatch('getServiceMans'))?.data;
      };
      if (withServicemans.value) {
          getServiceMans();
      }
      const onMarkerReady = (refData) => {
          const latLng = refData[1].value;
          if (!latLng) {
              return;
          }
          if ((requestToOpen.value?.facility === undefined
                  || requestToOpen.value?.facility === null)
              && (servicemans.value[0]?.facility === undefined
                  || servicemans.value[0]?.facility === null)) {
              return;
          }
          const {lat, lon} = requestToOpen.value?.facility || servicemans.value[0]?.facility;
          if (latLng[0] === lat && latLng[1] === lon) markerToOpen.value = refData;
      };

      const newMarker = (e) => {
          if (!editMode.value) {
              return;
          }
          emit('newMarker', e.get('coords'));
      };

      watch(markerToOpen, (refData) => {
          // eslint-disable-next-line no-underscore-dangle
          center.value = refData[1].value;
      });

      watch(withServicemans, () => {
          if (withServicemans.value) {
              getServiceMans();
          }
      });

      const token = process.env.VUE_APP_YANDEX_API_KEY;

      const settings = {
          apiKey: token,
          lang: 'ru_RU',
          coordorder: 'latlong',
          enterprise: false,
          version: '2.1'
      };

      const yandexMap = ref(null);
      const getBounds = async () => {
          const world = yandexMap.value.getGlobalPixelCenter();
          const container = yandexMap.value.container.getSize();
          const topLeftX = Math.floor((world[0] - container[0] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const topLeftY = Math.floor((world[1] - container[1] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const bottRightX = Math.ceil((world[0] + container[0] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const bottRightY = Math.ceil((world[1] + container[1] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));

          const coordData = {topLeftX, topLeftY, bottRightX, bottRightY};
          emit('mapChange', coordData);
      };
      const initMap = async (map) => {
          yandexMap.value = map;
          await getBounds();
      };

      const clusterOptions = ref({
          1: {
              clusterDisableClickZoom: false,
              clusterOpenBalloonOnClick: false,
              preset: 'islands#invertedVioletClusterIcons',
          },
      });

        const employeeIcon = ref({
            layout: 'default#imageWithContent',
            imageHref: '/map/employees.svg',
            imageSize: [43, 43],
            imageOffset: [-23, -43],
            content: '',
            contentOffset: [0, 0],
            contentLayout: ''
        });

      return {
          map,
          mapRef,
          attribution,
          center,
          initMap,
          getBounds,
          servicemans,
          onMarkerReady,
          settings,
          clusterOptions,
          newMarker,
          userCoords,
          employeeIcon,
          coordsIsLoaded,
      };
  },
};
</script>

<style lang="sass">
@import "~@/assets/scss/utils.scss"
@import 'src/@core/scss/base/core/colors/palette-variables.scss'
.map
  height: 100%
  max-height: 100vh
@include dark-theme
  .leaflet-popup-tip
    background: #283046
</style>
