<template>
  <div class="facilitiesList">
    <b-card no-body>
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
      >
        <b-tabs
          v-model="tabsModel"
          no-nav-style
          class="facilities-tabs"
          lazy
        >
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" />
            </template>

            <b-table
              ref="usersTable"
              class="position-relative min-height"
              hover
              selectable
              select-mode="single"
              :items="facilities"
              responsive
              :fields="customTableColumn"
              :per-page="0"
              :no-local-sorting="true"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :empty-text="t('facilities.table.empty-text')"
              @row-clicked="onRowClicked"
            >
              <!-- Column: Facility -->
              <template #cell(name)="{ item: facility }">
                <div class="d-flex align-items-center">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="facility.avatar"
                        :text="avatarText(facility.name)"
                        variant="light-primary"
                      />
                    </template>
                    <div class="text-dark font-weight-bold text-wrap">
                      {{ facility.name }}
                    </div>
                    <small class="text-muted">{{ facility.address }}</small>
                  </b-media>
                </div>
              </template>
                <template #cell(client)="{ item: facility }">
                <span class="overflow-hidden">
                    {{ facility.client ? facility.client.name : null }}
                </span>
                </template>

              <template #cell(manager)="{ item: facility }">
                <b-media vertical-align="center">
                  <template v-if="facility.manager">
                    {{ facility.manager.firstname }}
                    {{ facility.manager.surname ? facility.manager.surname : '' }}
                  </template>
                  <template v-else>
                    Нет ответсвенного
                  </template>
                </b-media>
              </template>

              <template #cell(contract_till)="{ item: facility }">
                <div class="d-flex align-items-center justify-content-between">
                  <Contract :end-date="facility.contract_till" />
                </div>
              </template>

                <template #cell(contract_number)="{ item: facility }">
                    <div class="d-flex align-items-center justify-content-between">
                        {{ facility.contract_number === null ? 'Нет' : facility.contract_number }}
                    </div>
                </template>

              <template #cell(created_at)="{ item: facility }">
                <div style="display:flex;align-items:center;justify-content:space-between;">
                  {{ facility.created_at | readableDate }}

                  <b-button
                    v-if="selectable && selected !== facility.id"
                    class="ml-1"
                    variant="primary"
                    style="width:115px"
                    @click="$emit('selected', facility)"
                  >
                    Выбрать
                  </b-button>
                  <b-button
                    v-else-if="selectable"
                    class="ml-1"
                    variant="secondary"
                    disabled
                    style="width:115px"
                  >
                    Выбрано
                  </b-button>
                </div>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  v-if="totalFacilities > 10"
                  cols="12"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    justify-content-sm-start
                    text-muted
                  "
                >
                  <span class="mr-1">{{ t('app.table.show-by') }}</span>
                  <b-form-group style="width: 90px; margin: 0">
                    <AppSelect
                      v-model="pagination.perPage"
                      style="width: 100%"
                      :searchable="false"
                      label="title"
                      :clearable="false"
                      :options="perPageOptions"
                    />
                  </b-form-group>
                  <span class="ml-1">
                    {{ t('app.table.of', { total: totalFacilities }) }}
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-if="totalPages > 1"
                    v-model="pagination.page"
                    :total-rows="totalFacilities"
                    :per-page="pagination.perPage"
                    first-number
                    last-number
                    :limit="isMobile ? 1 : 5"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="MapPinIcon" />
            </template>

            <FacilitiesMap
              :facilities="facilities"
              :selectable="selectable"
              :with-servicemans="showServicemans"
              :selected="selected"
              :card-target="target"
              @selected="$emit('selected', $event)"
              @mapChange="mapChange"
            />

            <ScrollWatcher @scrolled="scrollToEndMapView" />
          </b-tab>

          <template #tabs-end>
            <div
                :class="{'ml-2' : !isMobile, 'w-100 mt-1' : isMobile}"
                :style="(isMobile ? 'flex: auto' : '')"
            >
              <template v-if="!isMobile">
                <b-row>
                  <b-col
                      cols="12"
                      md="4"
                      class="d-flex align-items-center justify-content-start"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="searchQuery"
                          debounce="500"
                          :placeholder="t('dictionary.search')"
                      />
                    </b-input-group>
                  </b-col>
                  <b-col
                      v-if="tabsModel == 1"
                      cols="12"
                      md="3"
                      class="d-flex align-items-center justify-content-start"
                  >
                    <b-form-checkbox
                        v-model="showServicemans"
                        @change="setShowServicemans"
>
                      {{ t('facilities.field.showServicemans.label') }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                      class="d-flex justify-content-end"
                      cols="12"
                      :md="tabsModel == 1 && !isMobile ? '5' : '8'"
                  >
                      <sorting-configure
                              v-show="tabsModel !== 1"
                              :list="tableColumns"
                              @changeSorting="changeColumns"
                      />
                    <b-button
                        v-if="!readonly && $store.getters['user/permission']('facilities:create')"
                        variant="primary"
                        :disabled="isProcessing"
                        :to="{ name: 'facility-add', query: { clientId: byClientId } }"
                    >
                    <span class="text-nowrap">
                      {{ t('facilities.add') }}
                    </span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <div
                    class="d-flex mt-1 flex-column flex-fill align-items-end gap-1"
                >
                  <div class="d-flex w-100 align-items-center">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="searchQuery"
                          debounce="500"
                          :placeholder="t('dictionary.search')"
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center gap-1">
                    <b-form-checkbox
                        v-if="tabsModel == 1"
                        v-model="showServicemans"
                        @change="setShowServicemans"
>
                      {{ t('facilities.field.showServicemans.label') }}
                    </b-form-checkbox>
                    <b-button
                        v-if="!readonly && $store.getters['user/permission']('facilities:create')"
                        variant="primary"
                        :disabled="isProcessing"
                        :to="{ name: 'facility-add', query: { clientId: byClientId } }"
                    >
                    <span class="text-nowrap">
                      {{ t('facilities.add') }}
                    </span>
                    </b-button>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </b-tabs>
      </BOverlay>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
} from 'bootstrap-vue';
import {
  computed, ref, watch, toRefs, provide,
} from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useRouter } from '@/hooks/useRouter';
import Contract from '@/components/facilities/Contract.vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import FacilitiesMap from '@/components/facilities/FacilitiesMap.vue';
import useCache from '@/hooks/useCache';
import ScrollWatcher from "@/components/scrollWatcher/ScrollWatcher.vue";
import SortingConfigure from '@/components/sortingConfigure/sortingConfigure.vue';
import {useLocalStorage} from '@vueuse/core';

export default {
  name: 'FacilitiesList',
  components: {
    SortingConfigure,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BOverlay,
    BFormCheckbox,
    AppSelect,
    Contract,
    BTabs,
    BTab,
    FeatherIcon,
    ScrollWatcher,
    FacilitiesMap,
  },
  props: {
    byClientId: Number,
    readonly: Boolean,
    selectable: Boolean,
    selected: Number,
    target: String,
    noCache: Boolean,
    getAction: {
      type: String,
      default: 'facilities/getFacilities',
    },
    onePage: Boolean,
  },
  setup(props) {
    const {
      byClientId, target, noCache, getAction, onePage,
    } = toRefs(props);
    const { t } = useI18n();
    const store = useStore();
    const { router } = useRouter();
    const { dangerToast } = useToast();
    const showServicemans = ref(false);
    const isMobile = ref(false);
    showServicemans.value = store.state.facilities.showServicemans;
    provide('mapMarkerIcon', 'object');

    isMobile.value = store.state.app.isMobile;

    const usersTable = ref(null);
    const arrayColumns = [
      {key: 'name', label: 'Название', sortable: true, visible: true},
      { key: 'client', label: 'Клиент', sortable: true, visible: true},
      {key: 'manager', label: 'Ответственный', sortable: true, visible: true},
      {key: 'contract_till', label: 'Договор', sortable: true, visible: true},
      {key: 'created_at', label: 'Создано', sortable: true, visible: true},
      {key: 'contract_number', label: 'Номер договора', sortable: true, visible: true},
    ];
    const localStorageColumns = useLocalStorage('FacilitiesList', arrayColumns);
    const tableColumns = ref(localStorageColumns.value);
    const customTableColumn = computed(() => tableColumns.value.filter((i) => i.visible));
    const changeColumns = (e) => {
      tableColumns.value = [...e];
      localStorageColumns.value = [...e];
    };

    const cache = useCache();
    const cacheParams = ref({
      prefix: 'facilitiesList',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const tabsModel = cache.create({
      defaultValue: 0,
      name: 'tabsModel',
      ...cacheParams.value,
        number: true,
    });
    const facilities = ref([]);
    const totalFacilities = ref(0);

    const paginationPage = cache.create({
      defaultValue: '1',
      name: 'Page',
      ...cacheParams.value,
      storage: false,
    });
    const paginationPerPage = cache.create({
      defaultValue: '10',
      name: 'PerPage',
      ...cacheParams.value,
    });
    const pagination = ref({
      page: paginationPage,
      perPage: paginationPerPage,
    });
    const totalPages = computed(() => Math.ceil(totalFacilities.value / pagination.value.perPage));
    const perPageOptions = ['10', '25', '50', '100'];

    const searchQuery = cache.create({
      defaultValue: '',
      name: 'searchQuery',
      ...cacheParams.value,
      // storage: false,
    });

    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value,
    });
    const isSortDirDesc = cache.create({
      defaultValue: false,
      name: 'isSortDirDesc',
      ...cacheParams.value,
      storage: true,
      boolean: true,
    });

    const isProcessing = ref(false);
    const getFacilities = (payload) => store.dispatch(getAction.value, payload);
    const getFacilitiesMap = (payload) => store.dispatch('facilities/getFacilitiesMap', payload);

    const getList = async () => {
      isProcessing.value = true;
      const { result, data } = await getFacilities({
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...pagination.value,
        ...(!!byClientId.value && {
          clientId: byClientId.value,
        }),
      });
      isProcessing.value = false;

      if (result) {
        if (onePage.value) {
          facilities.value = data;
          return;
        }
          facilities.value = data.list;

        totalFacilities.value = data.pagination.total;
      }
    };
    const oldMapTails = ref(null);

    const getMapList = async (tails) => {
        oldMapTails.value = tails;
        isProcessing.value = true;
        const { result, data } = await getFacilitiesMap({
            q: searchQuery.value,
            ...(!!byClientId.value && {
                clientId: byClientId.value,
            }),
            ...tails
        });
        isProcessing.value = false;
        if (result) {
            if (onePage.value) {
                facilities.value = data;
                return;
            }
            facilities.value = data;
        }
    };

    const firstMapLoad = ref(true);
    const mapChange = (val) => {
        if ((JSON.stringify(oldMapTails.value) !== JSON.stringify(val))) {
            oldMapTails.value = val;
            if (firstMapLoad.value && searchQuery.value !== '') {
                firstMapLoad.value = false;
                getList();
            }
            if (!byClientId.value && searchQuery.value === '') {
                getMapList(val);
            }
        }
    };

      const scrollToEndMapView = async () => {
          const empLenght = facilities.value.length;
          if (!isProcessing.value && pagination.value.page < totalPages.value && empLenght > 0) {
              isProcessing.value = true;
              // eslint-disable-next-line radix
              pagination.value.page = parseInt(pagination.value.page) + 1;
              const { result, data } = await getFacilities({
                  q: searchQuery.value,
                  sortBy: sortBy.value,
                  sortDesc: isSortDirDesc.value,
                  // eslint-disable-next-line radix
                  page: parseInt(pagination.value.page) + 1,
                  perPage: pagination.value.perPage,
                  ...(!!byClientId.value && {
                      clientId: byClientId.value,
                  }),
              });
              isProcessing.value = false;

              if (result) {
                  facilities.value.push(...data.list);
                  totalFacilities.value = data.pagination.total;
                  return;
              }
              dangerToast(
                  t('errors.something-went-wrong.title'),
                  t('errors.something-went-wrong.text'),
                  data,
              );
          }
      };

    const isNeedToUpdateSortNavigation = ref(false);
    let sortNavigationDebounceTimer = null;
    watch([sortBy, isSortDirDesc], () => {
      clearTimeout(sortNavigationDebounceTimer);
      if (sortBy.value) {
        sortNavigationDebounceTimer = setTimeout(() => {
          isNeedToUpdateSortNavigation.value = true;
          sortNavigationDebounceTimer = null;
        }, 5);
      }
    });
    watch(isNeedToUpdateSortNavigation, (value) => {
      if (value) {
        getList();
        isNeedToUpdateSortNavigation.value = false;
      }
    });
    const page = computed(() => pagination.value.page);
    const perPage = computed(() => pagination.value.perPage);
    watch(
      page,
      () => {
        if (tabsModel.value !== 1 && tabsModel.value !== '1') {
          getList();
        }
      },
      { deep: true },
    );
    watch(perPage, () => {
      pagination.value.page = 1;
      getList();
    });

    watch(tabsModel, () => {
        if ((tabsModel.value === 1 || tabsModel.value === '1') && !byClientId.value) {
            facilities.value = [];
            if ((oldMapTails.value !== null)) {
                getMapList(oldMapTails.value);
            }
            return;
        }

      if (pagination.value.page !== 1) {
        facilities.value = [];
        pagination.value.page = 1;
        getList();
      }
    });

    watch(searchQuery, () => {
        if ((tabsModel.value === 1 || tabsModel.value === '1')
            && searchQuery.value === ''
            && !byClientId.value) {
            getMapList(oldMapTails.value);
            return;
        }
        pagination.value.page = 1;
        getList();
    });

    const getFacilitiesList = () => {
      if (isProcessing.value) return;
      getList();
    };

    (async () => {
      isProcessing.value = true;
      try {
          if (tabsModel.value !== 1 && tabsModel.value !== '1') {
              await getList();
          }
      } catch (err) {
        dangerToast(
          t('errors.something-went-wrong.title'),
          t('errors.something-went-wrong.text'),
          err,
        );
      } finally {
        isProcessing.value = false;
      }
    })();

    const onRowClicked = (facility) => {
      const routeData = { name: 'facility-view', params: { facilityId: facility.id } };
      if (target.value === '_blank') {
        const { href } = router.resolve(routeData);
        window.open(href, '_blank');
      } else router.push(routeData);
    };

    const setShowServicemans = () => {
      store.dispatch('facilities/setShowServicemans', showServicemans);
    };

    return {
      t,
      isMobile,
      customTableColumn,
      usersTable,
      tableColumns,
      changeColumns,
      facilities,
      totalFacilities,

      pagination,
      totalPages,
      perPageOptions,

      showServicemans,
      setShowServicemans,

      searchQuery,
      sortBy,
      isSortDirDesc,

      isProcessing,
      getFacilitiesList,

      onRowClicked,

      avatarText,

      tabsModel,

        mapChange,
        scrollToEndMapView,
    };
  },
};
</script>

<style lang="sass">
@import '@core/scss/vue/libs/vue-select.scss'
.facilitiesList
  display: flex
  flex-direction: column
  > .card
    flex: 1
.facilities-tabs
  .nav
    margin: 1.5rem !important
    display: flex
    align-items: center
    & > .nav-item
      width: 39px
      height: 34px
      border: 1px solid var(--primary)
      & + .nav-item
        margin-left: -1px
      &:first-child
        border-top-left-radius: 0.25rem
        border-bottom-left-radius: 0.25rem
      &:nth-last-child(2)
        border-top-right-radius: 0.25rem
        border-bottom-right-radius: 0.25rem
      & + div
        flex: 1
        & .form-control
          height: 38px
      & > .nav-link
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        &.active
          background-color: transparentize($primary, 0.80)
    & > .nav-item:first-child
</style>
