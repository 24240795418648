<template>
  <b-progress
    :value="workload"
    max="100"
    :class="progressClass"
    :variant="progressVariant"
    height="6px"
  />
</template>

<script>
import { ref, toRefs } from '@vue/composition-api';
import {
  BProgress,
} from 'bootstrap-vue';

export default {
  name: 'EmployeeWorkload',
  components: {
    BProgress,
  },
  props: {
    workload: {
      type: [String, Number],
      default: 0,
    },
  },
  setup(props) {
    const { workload } = toRefs(props);

    let progressClass = ref('');
    let progressVariant = ref('');
    const workloadNumber = ~~workload;

    let keyword = '';
    if (workloadNumber >= 66) keyword = 'danger';
    else if (workloadNumber >= 33) keyword = 'warning';
    else if (workloadNumber >= 0) keyword = 'success';

    progressClass = `progress-bar-${keyword}`;
    progressVariant = `${keyword}`;

    return {
      progressClass,
      progressVariant,
    };
  },
};
</script>
