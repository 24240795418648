<template>
  <b-link
    :to="{ name: 'home' }"
    :class="['logo', { 'small' : small && !isMobile }]"
  >
    <img
      v-if="!small || isMobile"
      key="logo"
      :src="require('@/assets/images/logo/logo.svg')"
      alt="logo"
    >
    <img
      v-else
      key="logo-small"
      :src="require('@/assets/images/logo/logo-small.svg')"
      alt="logo"
    >
    <div
      v-if="withText"
      :class="{
        'logo__text': true,
        'ml-1': true,
        'text-primary': state === 'primary',
        'text-body-heading': state === 'secondary',
      }"
    >
      {{ projectName }}
    </div>
  </b-link>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BLink,
} from 'bootstrap-vue';
import {useStore} from "@/hooks/useStore";
import {ref} from "@vue/composition-api";

export default {
  components: {
    BLink,
  },
  props: {
    small: Boolean,
    withText: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    ...mapGetters('app', [
      'projectName',
    ]),
  },
  setup() {
    const store = useStore();
    const isMobile = ref(false);
    isMobile.value = store.state.app.isMobile;

    return {
      isMobile
    };
  }
};
</script>

<style lang="scss">
  .logo {
    display: flex;
    align-items: center;
    height: 39px;
    &.small {
      img {
        height: 100%;
        max-width: unset;
      }
    }
    img {
      max-width: 125px;
      height: 30px;
    }
    &__text {
      white-space: nowrap;
      font-weight: 700;
      font-size: 18px;
      letter-spacing: -0.42px;
    }
  }
</style>
