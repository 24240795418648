<template>
    <div
v-click-outside=" () => showMenu = false"
class="position-relative margin-right-1"
>
        <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"

                variant="white"
                class="btn-outline-primary"
                @click="showMenu = !showMenu"
        >
            <feather-icon
                    icon="SettingsIcon"
                    size="14"
                    class="margin-8"
            />
            {{ t('app.buttonConfigure') }}
        </b-button>
        <div
v-show="showMenu"
class="position-absolute zindex-1"
>
<draggable
                    :list="modelSorting"
                    class="containers-fields"
                    @start="dragging = true"
             @end="dragging = false"
>
                <div
                        v-for="element in modelSorting"
                        :key="element.key"
                class="drag-item"
>
                    <feather-icon
                            icon="MenuIcon"
                            size="20"
                            class="cursor-pointer"
                    />    <div>
                    <b-form-checkbox
v-model="element.visible"
inline
class="mr-1"
>
{{ element.label }}
</b-form-checkbox>
</div>
                </div>
            </draggable>
        </div>
</div>
</template>

<script>
import {BButton, BFormCheckbox} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import {ref} from '@vue/composition-api';
import vClickOutside from 'v-click-outside'
import {useVModel} from '@vueuse/core';
import {useI18n} from '@/hooks/useI18n';

export default {
  name: 'SortingConfigure',
  components: { BButton, draggable, BFormCheckbox},
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    list: {
      required: true,
      validator: (value) => !value.map((i) => i.key && i.label && i.visible).find((i) => !i)
    }
  },
  setup(props) {
    const {t} = useI18n();
    const showMenu = ref(false);
    const modelSorting = useVModel(props, 'list', 'changeSorting', {deep: true}) // disable leak reactive
    const dragging = false;
    return {
      t, modelSorting, dragging, showMenu
    };
  },
};
</script>

<style scoped lang="scss">

.margin-right-1{
    margin-right: 1rem;
}
.margin-8 {
    margin-right: 8px;
}
.containers-fields{
    min-width: 240px;
    left: 0;
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    gap: 12px;
    border-radius: 5px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}

.drag-item{
    display: flex;
    flex-direction: row;
    gap:12px;
    color: var(--Text-Main, #4B4B4B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
