<template>
  <div
    :id="id"
    class="scrollWatcher"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ScrollWatcher',
  props: {
    id: {
      type: String,
      default: 'scrollWatcher',
    },
  },
  mounted() {
    const observer = new IntersectionObserver(((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting === true) this.$emit('scrolled');
    }), { threshold: [0] });

    observer.observe(this.$el);
  },
  methods: {

  },
};
</script>

<style lang="sass">
.scrollWatcher
  margin-top: -1px
  padding-bottom: 1px
</style>
