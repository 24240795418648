<template>
    <b-card
        @click="emit('clicked', employee.id)"
    >
        <b-col class="col-auto">
            <b-row
                align-h="between"
                class="d-flex flex-md-row"
            >
                <div class="employee-ballon-card">
                    <b-media
                        vertical-align="center"
                        class="d-flex align-items-start"
                    >
                        <template #aside>
                            <b-link
                                :to="{ name: 'employee-view', params: { employeeId: employee.id } }"
                            >
                                <b-avatar
                                    class="employee-small-card-avatar"
                                    size="32"
                                    :src="employee.avatar"
                                    :text="avatarText(`${employee.firstname} ${employee.surname}`)"
                                />
                            </b-link>
                        </template>

                        <div class="d-flex justify-content-between gap-2">
                            <div>
                                <b-link
                                    :to="{
                                    name: 'employee-view',
                                    params: { employeeId: employee.id }
                                }"
                                    class="font-weight-bold
                                    d-block text-nowrap overflow-hidden text-dark"
                                    style="max-width: 150px; text-overflow: ellipsis"
                                    :title="fullName"
                                >
                                    {{ fullName }}
                                </b-link>
                                <small
                                    v-if="employee.role"
                                    class="second-text"
                                >
                                    {{ employee.role.description }}
                                </small>
                                <div
                                    style="max-width: 100px"
                                    class="mt-50"
                                >
                                    <employee-workload :workload="employee.workload" />
                                </div>
                            </div>
                            <div
                                class="d-flex flex-column
                                align-items-center justify-content-between"
                            >
                                <div class="phone">
                                    <small class="second-text">{{ employee.phone }}</small>
                                </div>
                                <div>
                                    <small
                                        class="second-text"
                                    >
                                        {{ dateFormatted }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </b-media>
                </div>
            </b-row>
        </b-col>
    </b-card>
</template>

<script>
import {ref, toRefs} from '@vue/composition-api';
import {
    BAvatar, BCard, BCol, BLink, BMedia, BRow,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import { formatDate } from '@/utils/time';
import { avatarText } from '@core/utils/filter';
import EmployeeWorkload from "@/components/employeeWorkload/EmployeeWorkload.vue";

export default {
    name: 'EmployeeMapCard',
    components: {
        BMedia,
        BCard,
        BCol,
        BAvatar,
        BRow,
        BLink,
        EmployeeWorkload,
    },
    props: {
        employee: {
            type: Object,
            default: () => {},
        },
        withPatronymic: {
            type: Boolean,
            default: false,
        },
        map: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const { employee, withPatronymic } = toRefs(props);
        const inProcess = ref(true);

        const fullName = withPatronymic.value
            ? `${employee.value.surname} ${employee.value.firstname} ${employee.value?.patronymic === null ? '' : employee.value?.patronymic}`
            : `${employee.value.firstname} ${employee.value.surname}`;

        const dateFormatted = formatDate(employee.value.last_geo_location?.time, {
            withYear: false,
            withHours: true,
            withMinutes: true,
        });

        return {
            t,
            emit,
            inProcess,
            fullName,
            avatarText,

            dateFormatted,
        };
    },
}
</script>

<style lang="sass" scoped>
@import "~@/assets/scss/utils.scss"
@import 'src/@core/scss/base/core/colors/palette-variables.scss'
.card
    margin-bottom: 0
.card-body
    padding: 0.5rem
.card .card.requestCard
    .request-type-badge
        color: #82868B

.employee-small-card
    &-avatar
        background-color: transparentize($warning, 0.88)
        color: var(--red)
        font-weight: 700
    &-toggleRequests
        color: var(--secondary)
@include dark-theme
    .card .card.requestCard
        .request-type-badge
            color: #ffffff
    .second-text
        color: #b8c2cc
    .leaflet-popup-tip
        background: #283046
</style>
