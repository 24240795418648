<template>
    <div v-if="latLng">
        <div v-if="type === 'request'">
            <ymapMarker
                ref="markerRef"
                :coords="latLng"
                :marker-id="'request' + request.id"
                :hint-content="request.name"
                cluster-name="1"
                :icon="markerIcon"
            >
                <div slot="balloon">
                    <slot />
                </div>
            </ymapMarker>
        </div>
        <div v-else>
            <ymapMarker
                ref="markerRef"
                :coords="latLng"
                :marker-id="'request1' + request.id"
                :hint-content="request.name"
                :icon="employeeIcon"
                cluster-name="1"
            >
                <div slot="balloon">
                    <slot />
                </div>
            </ymapMarker>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, ref, toRefs} from '@vue/composition-api';
import {ymapMarker} from "vue-yandex-maps";

export default {
  name: 'RequestMarker',
  components: {
      ymapMarker
  },
  props: {
    request: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'request'
    },
    updateTime: {
      type: [Date, String, Number],
      default: null,
    },
    selected: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const { request, type, selected } = toRefs(props);
    const iconAnchor = [16, 37];
    const iconSize = [16, 37];
    const initials = type.value === 'employee'
        ? computed(() => request.value.firstname.substring(0, 1)
            + request.value.surname.substring(0, 1))
        : '';

    const markerRef = ref(null);

    const latLng = computed(() => {
      if (type.value === 'employee') {
        if (!request.value.last_geo_location && request.value.last_geo_location?.lat === undefined
            && request.value.last_geo_location?.lng === undefined) {
          return null;
        }
        return [request.value.last_geo_location?.lat, request.value.last_geo_location?.lng]
      }
      if (!request.value.facility && request.value.facility?.lat === undefined
          && request.value.facility?.lon === undefined) {
        return null;
      }
      return [request.value.facility?.lat, request.value.facility?.lon];
    });

      onMounted(() => {
          emit('markerReady', [markerRef, latLng]);
      });

    const employeeIcon = ref({
        layout: 'default#imageWithContent',
        imageHref: '',
        imageSize: [30, 40],
        imageOffset: [-15, -38],
        content: initials.value,
        contentOffset: [0, 0],
        contentLayout: selected.value
            ? `<div class="employeeIcon"><span>$[properties.iconContent]</span><img src="/map/employee-selected.svg" alt="img"></div>`
            : 'avatar' in request.value && request.value.avatar
            ? `<div class="employeeIcon">
                <span class="employeeIcon__image">
                  <img style="border-radius: 100%" src=${request.value.avatar} />
                </span>
                <img src="/map/employee.svg" alt="img">
               </div>`
            : `<div class="employeeIcon">
                <span>
                  $[properties.iconContent]
                </span>
                <img src="/map/employee.svg" alt="img">
               </div>`
    });

    const markerIcon = ref({
        layout: 'default#imageWithContent',
        imageHref: '',
        imageSize: [30, 40],
        imageOffset: [-15, -38],
        content: initials.value,
        contentOffset: [0, 0],
        contentLayout: `<div class="employeeIcon"><img src="/map/object.svg" alt="img"></div>`,
    });

    return {
        employeeIcon,
      iconSize,
      iconAnchor,
      markerRef,
      latLng,
      initials,
      markerIcon,
    };
  },
};
</script>
