<template>
  <div class="map-view">
    <b-row
        v-if="!isMobile"
        class="px-2"
    >
      <b-col class="map-view__items">
        <slot :openPopup="openPopup" />
      </b-col>
      <b-col>
        <Map
          :items="items"
          center-by-user-location
          :markers="markers"
          :recenter-to.sync="recenterTo"
          :with-servicemans="withServicemans"
          :popup-to-open.sync="popupToOpen"
          @mapChange="$emit('mapChange', $event)"
          @selected="$emit('selected', $event)"
        />
      </b-col>
    </b-row>
    <b-col
        v-else
    >
      <b-col>
        <Map
            :items="items"
            center-by-user-location
            :markers="markers"
            :recenter-to.sync="recenterTo"
            :with-servicemans="withServicemans"
            :popup-to-open.sync="popupToOpen"
            @selected="$emit('selected', $event)"
        />
      </b-col>
      <b-col class="map-view__items">
        <slot :openPopup="openPopup" />
      </b-col>
    </b-col>
  </div>
</template>

<script>
import { ref, toRefs, nextTick } from '@vue/composition-api';
import { BRow, BCol } from 'bootstrap-vue';
import { useStore } from "@/hooks/useStore";
import Map from './Map.vue';

export default {
  components: {
    BRow,
    BCol,
    Map,
  },
  props: {
    withServicemans: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    markers: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const { markers } = toRefs(props);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    const recenterTo = ref(null);
    const recenter = (item) => {
      recenterTo.value = [item.lat, item.lon];
    };

    const popupToOpen = ref(null);
    const openPopup = (item) => {
      const marker = markers.value.find((i) => i.id === item.id);
      if (marker) {
        popupToOpen.value = marker;
        recenter(item);
      }
    };

    nextTick(() => {
      if (markers.value.length) openPopup(markers.value[0].data);
    });

    return {
      recenter,
      recenterTo,
      popupToOpen,
      openPopup,
      isMobile,
    };
  },
};
</script>

<style lang="scss">
.map-view {
  & .map {
    height: calc(85vh - 144px);
    position: sticky;
    top: 114px;
  }
  &__items {
    padding-bottom: 32px;
  }
}
</style>
