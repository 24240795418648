<template>
    <ymapMarker
            ref="markerRef"
            :coords="marker.latLng"
            :marker-id="'marker' + marker.id"
            :hint-content="marker.name"
            cluster-name="1"
            :icon="employeeIcon"
            @balloonopen="setAddress"
    >
        <div v-if="marker.type && marker.type === 'finded'" />
        <div
v-else
slot="balloon"
>
            <component
                :is="marker.component"
                :item="marker.data"
                :request="marker.data"
                :selectable="marker.props && marker.props.selectable"
                :marker-ref="markerRef"
                @close="close"
                @selected="$emit('selected', $event)"
            />
        </div>
    </ymapMarker>
</template>

<script>
import {
    inject,
    ref, toRefs,
} from '@vue/composition-api';
import {ymapMarker} from "vue-yandex-maps";

export default {
  components: {
      ymapMarker,
  },
  props: {
    marker: {
      type: Object,
      default: () => ({}),
    },
    popupToOpen: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const markerRef = ref(null);
    const { marker } = toRefs(props);
    const onMarkerReady = () => {};

    const close = () => {
      markerRef.value.mapObject.closePopup();
    };

    const mapMarkerIcon = inject('mapMarkerIcon', 'object');

    const objectMarkerIcon = () => {
        if (marker.value.type) {
            return marker.value.type === 'finded' ? '/map/object-finded.svg' : '/map/object.svg';
        }
        return '/map/object.svg';
    };

      const employeeIcon = ref({
          layout: 'default#imageWithContent',
          imageHref: mapMarkerIcon === 'object' ? objectMarkerIcon() : '/map/employees.svg',
          imageSize: [43, 43],
          imageOffset: [-23, -43],
          content: '',
          contentOffset: [0, 0],
          contentLayout: ''
      });

      const setAddress = () => {
          if (document.getElementById('addressField')) {
              document.getElementById('addressField').value = marker.value.data.name;
          }
      };

    return {
      markerRef,
      onMarkerReady,
      close,
        employeeIcon,
        setAddress,
    };
  },
};
</script>

<style lang="sass">
.map-marker
  & .leaflet-popup-content-wrapper
    padding: 0
    & .leaflet-popup-content
      margin: 0
      width: 350px !important
      max-width: 70vw !important
</style>
